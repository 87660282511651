<template>
  <!-- DEBUT création page ..... -->
  <div id="app-fonction-add">
   <!-- DEBUT carte de la fonction ..... -->
    <vx-card >

      <!-- DEBUT délimitation de la section du formulaire ..... -->
        <div class="vx-row">

          <div class="vx-col w-full">
            <div class="flex items-end px-3">
               <vs-divider position="left">
                <feather-icon svgClasses="w-6 h-6" icon="PlusSquareIcon" class="mr-2" />
                <span class="font-medium text-lg leading-none">Nouvelle Fonction</span>
               </vs-divider>            
            </div>
          </div>

        </div>
        <!-- FIN délimitation de la section du formulaire ..... -->
        <!-- ........................................... -->
        
        <!-- DEBUT les champ du formulaire... -->
        <div class="vx-row mb-6">
          <div class="vx-col w-full">
            <vs-input class="w-full" type="text" label="Libelle" v-validate="'required'" name="libelle" v-model="libelle" />
            <span class="text-danger text-sm"  v-show="errors.has('libelle')">{{ errors.first('libelle') }}</span>
          </div>
        </div>
         <!-- Fin les champ du formulaire... -->
         <!-- ........................................... -->

         <!-- DEBUT les bouton Enregistrer et Annuler ... -->
        <div class="vx-row">
          <div class="vx-col sm:w-2/3 w-full ml-auto">
            <vs-button class="mr-3 mb-2" @click="creatFonction" :disabled="!validateForm">Enregistrer</vs-button>
            <vs-button color="warning" type="border" class="mb-2" @click="libelle = ''; ">Annuler</vs-button>
          </div>
        </div>
        <!-- FIN les bouton Enregistrer et Annuler ... -->

    </vx-card>
    <!-- FIN carte de la fonction ..... -->
  </div>
 <!-- FIN création page ..... -->
</template>

<script>
 
export default {
  data () {
    return {
      popupFonction: false,
      popupFonctionUpdate: false,
      // status: false,
      libelle: '',
      selected:[],
      fonctions:[],
      fonctionId: ''
    }
  },
  computed: {
    validateForm () {
      return !this.errors.any() && this.libelle !== ''
    },
    fonctionData () {
      return this.$store.state.fonction.fonctions
    }
  },

  methods: {
    // DEBUT AJOUT fonction 
    creatFonction () {
      const payload = {
        libelle: this.libelle
        // status: this.status
      }
      this.$vs.loading({
        type:'sound'
      })
      this.$store.dispatch('fonction/addFonction', payload)
        .then(() => {
          this.$vs.notify({
            title: 'success',
            text: 'success',
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'success'
          })
          //this.popupFonction = false
          this.resete_data()
        })
        .finally(() => {

          //this.$vs.loading.close()
          // this.popupFonction = false

        })
        .catch(err => {
          console.error(err)
        })
    }
    // FIN AJOUT fonction 

  }


}   


</script>
